import menuAccount from "./menus/account.menu"

export default {
  menu: [
    {
      text: "个人中心",
      items: menuAccount
    }
  ]
}
