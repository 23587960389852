export default [
  {
    path: "/auth",
    redirect: "/auth/profile",
  },
  {
    path: "/auth/signup",
    name: "auth-signup",
    component: () => import("@/views/auth/Signup.vue"),
    meta: {
      layout: "auth",
      title: "注册",
    }
  },
  {
    path: "/auth/agree",
    name: "auth-agree",
    component: () => import("@/views/auth/Agree.vue"),
    meta: {
      layout: "public",
      title: "服务条款",
    }
  },
  {
    path: "/auth/policy",
    name: "auth-policy",
    component: () => import("@/views/auth/Policy.vue"),
    meta: {
      layout: "public",
      title: "隐私政策",
    }
  },
  {
    path: "/auth/login",
    name: "auth-login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "auth",
      title: "登录",
    }
  },
  {
    path: "/auth/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      layout: "auth",
      title: "忘记密码",
    }
  },
  {
    path: "/auth/reset-password/:token",
    name: "auth-reset-password",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      layout: "auth",
      title: "重新设置密码",
    }
  },
  {
    path: "/auth/profile",
    name: "auth-profile",
    component: () => import("@/views/auth/ProfilePage.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      title: "用户信息",
    }
  },
  {
    path: "/auth/logout",
    name: "auth-logout",
    component: () => import("@/views/auth/Logout.vue"),
    meta: {
      layout: "auth",
      title: "注销退出",
    }
  }
]
