export default [
  {
    path: "/order/recharge-coder/:id",
    name: "order-recharge-coder",
    component: () => import("@/views/order/RechargeCoder.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      category: "订单",
      title: "领域类充值",
    }
  },
  {
    path: "/order/list",
    name: "order-list",
    component: () => import("@/views/order/List.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      category: "订单",
      title: "订单列表",
    }
  },
  {
    path: "/order/detail/:id",
    name: "order-detail",
    component: () => import("@/views/order/Detail.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      category: "订单",
      title: "订单详情",
    }
  },
]
