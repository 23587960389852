<script setup lang="ts">
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageTitle from "@/components/PageTitle.vue";
import MainSidebar from "@/components/MainSidebar.vue";
import MainAppbar from "@/components/MainAppbar.vue";
import { useCustomizeThemeStore } from "@/stores/customizeThemeStore";
const customizeTheme = useCustomizeThemeStore();
</script>

<template>
  <MainSidebar />
  <MainAppbar />
  <v-main
    v-touch="{
      left: () => (customizeTheme.mainSidebar = false),
      right: () => (customizeTheme.mainSidebar = true),
    }"
    class="main-container"
  >
    <div class="d-none d-sm-block px-3">
      <PageTitle />
      <Breadcrumb />
    </div>

    <div class="flex-fill">
      <slot></slot>
    </div>
  </v-main>
</template>

<style scoped>
.scrollnav {
  height: calc(100vh - 326px);
}
.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
