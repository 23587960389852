export default [
  {
    icon: "mdi-account-box-outline",
    key: "menu.profileDetails",
    text: "资料设置",
    link: "/auth/profile",
  },
  {
    icon: "mdi-credit-card-outline",
    key: "menu.orders",
    text: "我的订单",
    link: "/order/list",
  }
]
