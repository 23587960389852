<template>
  <v-app>
    <component :is="currentLayout" v-if="isRouterLoaded">
      <router-view> </router-view>
    </component>
    <BackToTop />
    <Snackbar />
  </v-app>
</template>

<script setup lang="ts">
import PublicLayout from "@/layouts/PublicLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import CoderLayout from "@/layouts/CoderLayout.vue";
import NoTitleLayout from "@/layouts/NoTitleLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import BackToTop from "@/components/BackToTop.vue";
import Snackbar from "@/components/Snackbar.vue";

const route = useRoute();

const isRouterLoaded = computed(() => {
  if (route.name !== null) return true;
  return false;
});

const layouts = {
  public: PublicLayout,
  default: DefaultLayout,
  coder: CoderLayout,
  noTitle: NoTitleLayout,
  empty: EmptyLayout,
  auth: AuthLayout,
};

type LayoutName = "public" | "default" | "coder" | "noTitle" | "empty" | "auth" | "error";

const currentLayout = computed(() => {
  const layoutName = route.meta.layout as LayoutName;
  if (!layoutName) {
    return EmptyLayout;
  }
  return layouts[layoutName];
});
</script>

<style scoped></style>
