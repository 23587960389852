export default [
  {
    path: "/vo/list",
    name: "vo-list",
    component: () => import("@/views/vo/List.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "VO类设计",
      title: "VO列表",
    }
  },
  {
    path: "/vo/create",
    name: "vo-create",
    component: () => import("@/views/vo/Create.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "VO类设计",
      title: "创建VO类",
    }
  },
  {
    path: "/vo/create-from-domain/:id",
    name: "vo-create-from-domain",
    component: () => import("@/views/vo/CreateFromDomain.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "VO类设计",
      title: "创建VO类",
    }
  },
  {
    path: "/vo/modify/:id",
    name: "vo-modify",
    component: () => import("@/views/vo/Modify.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "VO类设计",
      title: "编辑VO类",
    }
  }
]
