export default [
  {
    path: "/app/list",
    name: "app-list",
    component: () => import("@/views/app/List.vue"),
    meta: {
      layout: "coder",
      category: "应用设计",
      title: "应用列表",
    }
  },
  {
    path: "/app/detail/:id",
    name: "app-detail",
    component: () => import("@/views/app/Detail.vue"),
    meta: {
      layout: "coder",
      category: "应用设计",
      title: "应用详情",
    }
  },
  {
    path: "/app/create",
    name: "app-create",
    component: () => import("@/views/app/Create.vue"),
    meta: {
      layout: "coder",
      category: "应用设计",
      title: "创建应用",
    }
  },
  {
    path: "/app/modify/:id",
    name: "app-modify",
    component: () => import("@/views/app/Modify.vue"),
    meta: {
      layout: "coder",
      category: "应用设计",
      title: "编辑应用",
    }
  },
  // {
  //   path: "/page/list",
  //   name: "page-list",
  //   component: () => import("@/views/page/List.vue"),
  //   meta: {
  //     layout: "coder",
  //     category: "应用设计",
  //     title: "页面设计",
  //   }
  // },
]
