<script setup lang="ts">
const menus = [
  {
    title: "Midjourney",
    logo: "/sites/image/midjourney.png",
    link: "https://www.midjourney.com/home",
  },
  {
    title: "Stable Diffusion",
    logo: "/sites/image/stablediffusion.png",
    link: "https://stability.ai/",
  },
  {
    title: "文心一格",
    logo: "/sites/image/yige.png",
    link: "https://yige.baidu.com/",
  },
  {
    title: "堆友AI反应堆",
    logo: "/sites/image/d_design.png",
    link: "https://d.design/",
  },
  {
    title: "海艺AI",
    logo: "/sites/image/seaart.png",
    link: "https://www.seaart.ai/zhCN",
  },
  {
    title: "photoroom免费AI抠图",
    logo: "/sites/image/photoroom.png",
    link: "https://www.photoroom.com/tools/background-remover",
  },
  {
    title: "WHEE 美图秀秀AI",
    logo: "/sites/image/whee.png",
    link: "https://www.whee.com/?utm_source=ai-bot.cn",
  },
  {
    title: "Adobe Firefly",
    logo: "/sites/image/firefly.png",
    link: "https://firefly.adobe.com/",
  },
  {
    title: "通义万相",
    logo: "/sites/image/wanxiang.png",
    link: "https://tongyi.aliyun.com/wanxiang/",
  },
  {
    title: "LibLib AI绘画",
    logo: "/sites/image/liblib.png",
    link: "https://www.liblib.art/",
  },
  {
    title: "Mokker 产品图AI背景",
    logo: "/sites/image/mokker.png",
    link: "https://mokker.ai/zh/",
  },
  {
    title: "触手AI 新世代AI创作工具",
    logo: "/sites/image/acgnai.png",
    link: "https://www.acgnai.com/home",
  },
  {
    title: "触站AI",
    logo: "/sites/image/huashi6.png",
    link: "https://www.huashi6.com/ai/draw",
  },
  {
    title: "标小智 智能Logo设计",
    logo: "/sites/image/logosc.png",
    link: "https://www.logosc.cn/start",
  },
  {
    title: "Vega AI",
    logo: "/sites/image/vegaai.png",
    link: "https://vegaai.net/",
  },
  {
    title: "吐司TusiArt",
    logo: "/sites/image/tusiart.png",
    link: "https://tusiart.com/",
  },
  {
    title: "Canva AI画像生成",
    logo: "/sites/image/canva.png",
    link: "https://www.canva.cn/",
  },
  {
    title: "Bigjpg 图片无损放大",
    logo: "/sites/image/bigjpg.png",
    link: "https://bigjpg.com/",
  },
  {
    title: "TinyPNG智能压缩",
    logo: "/sites/image/tinify.png",
    link: "https://tinify.cn/",
  }
]
</script>

<template>
  <v-menu location="bottom right" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" class="text-none" stacked>
        AI绘画
      </v-btn>
    </template>
    <v-list elevation="1" lines="two" density="compact" width="260">
      <v-list-item v-for="(menu, i) in menus" :key="i" :href="menu.link" target="_blank">
        <template v-slot:prepend>
          <v-img :src="menu.logo" :width="30" :alt="menu.title" />
        </template>
        <div class="ml-2">
          <v-list-item-title class="font-weight-bold text-primary">{{
              menu.title
          }}</v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
</style>
