export default [
  {
    path: "/page/admin",
    name: "page-list-admin",
    component: () => import("@/views/page/ListAdmin.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "页面设计",
      title: "后台页面列表",
    }
  },
  {
    path: "/page/user",
    name: "page-list-user",
    component: () => import("@/views/page/ListUser.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "页面设计",
      title: "会员页面列表",
    }
  },
  {
    path: "/page/api/:id",
    name: "page-api",
    component: () => import("@/views/page/RestApi.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "页面设计",
      title: "接口调用",
    }
  },
  {
    path: "/page/config/:id",
    name: "page-config",
    component: () => import("@/views/page/Config.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
      category: "页面设计",
      title: "页面设计",
    }
  }
]
