<script setup lang="ts">
const menus = [
  // {
  //   title: "精选SD大模型",
  //   logo: "/sites/kimi.png",
  //   link: "/models/sd",
  // },
  // {
  //   title: "精选Lora大模型",
  //   logo: "/sites/kimi.png",
  //   link: "/models/lora",
  // },
  // {
  //   title: "精选大语言模型",
  //   logo: "/sites/kimi.png",
  //   link: "/models/llm",
  // },
  // {
  //   title: "大模型训练委托",
  //   logo: "/sites/kimi.png",
  //   link: "/models/train",
  // },
  {
    title: "Civit AI(C站)",
    logo: "/sites/llm/civitai.png",
    link: "https://civitai.com/",
  },
  {
    title: "Hugging Face",
    logo: "/sites/llm/huggingface.png",
    link: "https://huggingface.co/",
  },
  {
    title: "炼丹阁",
    logo: "/sites/image/tusiart.png",
    link: "https://tusiart.com/models",
  },
  {
    title: "哩布哩布",
    logo: "/sites/image/liblib.png",
    link: "https://www.liblib.art/",
  },
]
</script>

<template>
  <v-menu location="bottom right" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" class="text-none" stacked>
        AI模型下载
      </v-btn>
    </template>
    <v-list elevation="1" lines="two" density="compact" width="200">
      <v-list-item v-for="(menu, i) in menus" :key="i" :href="menu.link" target="_blank">
        <template v-slot:prepend>
          <v-img :src="menu.logo" :width="30" :alt="menu.title" />
        </template>
        <div class="ml-2">
          <v-list-item-title class="font-weight-bold text-primary">
            {{menu.title }}
          </v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
</style>
