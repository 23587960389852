<script setup lang="ts">
const menus = [
  {
    title: "Kimi",
    logo: "/sites/text/kimi.png",
    link: "https://kimi.moonshot.cn/",
  },
  {
    title: "讯飞绘文",
    logo: "/sites/text/turbodesk_xfyun.png",
    link: "https://turbodesk.xfyun.cn/home",
  },
  {
    title: "WPS智能写作",
    logo: "/sites/text/wps.png",
    link: "https://ai.wps.cn/",
  },
  {
    title: "豆包",
    logo: "/sites/text/doubao.png",
    link: "https://www.doubao.com/chat/",
  },
  {
    title: "Copy.ai",
    logo: "/sites/text/copyai.png",
    link: "https://www.copy.ai/",
  },
  {
    title: "Jasper.ai",
    logo: "/sites/text/jasper.png",
    link: "https://www.jasper.ai/",
  },
  {
    title: "Notion AI",
    logo: "/sites/text/notion.png",
    link: "https://www.notion.so/",
  },
  {
    title: "Writesonic",
    logo: "/sites/text/writesonic.png",
    link: "https://writesonic.com/",
  },
  {
    title: "DeepL Write",
    logo: "/sites/text/deepl.png",
    link: "https://www.deepl.com/zh/write",
  },
  {
    title: "字语智能",
    logo: "/sites/text/getgetai.png",
    link: "https://www.getgetai.com/",
  },
  {
    title: "密塔写作猫",
    logo: "/sites/text/mita.png",
    link: "https://xiezuocat.com/",
  },
  {
    title: "深言达意",
    logo: "/sites/text/shenyandayi.png",
    link: "https://www.shenyandayi.com/",
  },
  {
    title: "笔灵AI",
    logo: "/sites/text/ibiling.png",
    link: "https://ibiling.cn/",
  },
  {
    title: "小鱼AI写作",
    logo: "/sites/text/xiaoyuxiezuo.png",
    link: "https://www.xiaoyuxiezuo.com/",
  },
  {
    title: "千笔AI论文写作",
    logo: "/sites/text/aipaperpass.png",
    link: "https://www.aipaperpass.com/",
  },
  {
    title: "火山写作",
    logo: "/sites/text/writingo.png",
    link: "https://www.writingo.net/",
  },
  {
    title: "Effidit 腾讯创作助手",
    logo: "/sites/text/effidit.png",
    link: "https://effidit.qq.com/",
  },
];
</script>

<template>
  <v-menu location="bottom right" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" class="text-none" stacked>
        AI创作
      </v-btn>
    </template>
    <v-list elevation="1" lines="two" density="compact" width="240">
      <v-list-item v-for="(menu, i) in menus" :key="i" :href="menu.link" target="_blank">
        <template v-slot:prepend>
          <v-img :src="menu.logo" :width="30" :alt="menu.title" />
        </template>
        <div class="ml-2">
          <v-list-item-title class="font-weight-bold text-primary">{{
              menu.title
          }}</v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
</style>
