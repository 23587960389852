export default [
  {
    path: "/grpc/list",
    name: "grpc-list",
    component: () => import("@/views/grpc/List.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "gRPC通讯",
      title: "gRPC服务列表",
    }
  },
  {
    path: "/grpc/create",
    name: "grpc-create",
    component: () => import("@/views/grpc/Create.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "gRPC通讯",
      title: "新增gRPC服务",
    }
  },
  {
    path: "/grpc/proto",
    component: () => import("@/views/grpc/Proto.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "gRPC通讯",
      title: "proto设计",
    },
  }
]
