<!--用户在线状态切换-->
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { UserStatusStore, UserStatus } from "@/stores/UserStatusStore";
const userStatusStore = UserStatusStore();

const menuVisible = ref(false);
const userStatusList: UserStatus[] = [
  {
    code: "online",
    label: "在线",
    color: "success",
  },
  {
    code: "away",
    label: "离开",
    color: "warning",
  },
  {
    code: "busy",
    label: "忙碌",
    color: "error",
  },
  {
    code: "offline",
    label: "下线",
    color: "grey",
  },
];

// 页面加载时初始化用户在线状态
onMounted(() => {
  userStatusStore.initializeStatus();
});
const currentStatus = computed(() => userStatusStore.userStatus);

// 切换在线状态
const setStatus = (status: string) => {
  const selectedStatus = userStatusList.find(
    (userStatus) => userStatus.code === status
  ) as UserStatus;
  userStatusStore.setStatus(selectedStatus);
  menuVisible.value = false;
};
</script>

<template>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    location="bottom"
    origin="top"
    activator="parent"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        width="60"
        variant="text"
        size="small"
        :color="currentStatus.color"
        v-bind="attrs"
        v-on="on"
        @mouseenter="menuVisible = true"
      >
        {{ currentStatus.label }}
      </v-btn>
    </template>
    <v-list elevation="1">
      <v-list-item
        v-for="status in userStatusList"
        :key="status.code"
        @click="setStatus(status.code)"
        density="compact"
      >
        <v-list-item-title class="text-body-2">
          <v-icon size="small" :color="status.color">mdi-circle-medium</v-icon>
          {{ status.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss"></style>
