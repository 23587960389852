export default [
  {
    path: "/prompt/midjourney",
    component: () => import("@/views/prompt/Midjourney.vue"),
    meta: {
      requiresAuth: true,
      layout: "public",
      category: "AIGC咒语",
      title: "Midjourney咒语",
    },
  },
  {
    path: "/prompt/sd",
    component: () => import("@/views/prompt/SD.vue"),
    meta: {
      requiresAuth: true,
      layout: "public",
      category: "AIGC咒语",
      title: "SD咒语",
    },
  },
  {
    path: "/prompt/kling",
    component: () => import("@/views/prompt/Kling.vue"),
    meta: {
      requiresAuth: true,
      layout: "public",
      category: "AIGC咒语",
      title: "可灵AI视频咒语",
    },
  },
  {
    path: "/prompt/gpt",
    component: () => import("@/views/prompt/GPT.vue"),
    meta: {
      requiresAuth: true,
      layout: "public",
      category: "AIGC咒语",
      title: "GPT提示词工程",
    },
  },
];
