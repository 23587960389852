<script setup lang="ts">
const menus = [
  {
    title: "网易天音",
    logo: "/sites/audio/tianyin.png",
    link: "https://tianyin.music.163.com/",
  },
  {
    title: "Suno AI",
    logo: "/sites/audio/suno.png",
    link: "https://suno.com/",
  },
  {
    title: "Splash",
    logo: "/sites/audio/splashmusic.png",
    link: "https://www.splashmusic.com/",
  },
  {
    title: "魔音工坊",
    logo: "/sites/audio/moyin.png",
    link: "https://www.moyin.com/",
  },
  {
    title: "讯飞智作",
    logo: "/sites/audio/xunfei_peiyin.png",
    link: "https://peiyin.xunfei.cn/",
  },
  {
    title: "网易云音乐·X Studio",
    logo: "/sites/audio/xstudio.png",
    link: "https://xstudio.music.163.com/",
  },
  {
    title: "海绵音乐",
    logo: "/sites/audio/haimian.png",
    link: "https://www.haimian.com/featured",
  },
  {
    title: "TME Studio",
    logo: "/sites/audio/tme_studio.png",
    link: "https://y.qq.com/tme_studio/index.html",
  },
  {
    title: "Udio 音乐创作社交平台",
    logo: "/sites/audio/udio.png",
    link: "https://www.udio.com/",
  },
  {
    title: "Jammable 翻唱歌曲",
    logo: "/sites/audio/jammable.png",
    link: "https://www.jammable.com/",
  },
  {
    title: "音虫 AI音乐编曲和录音",
    logo: "/sites/audio/soundbug.png",
    link: "https://www.soundbug.com/",
  },
  {
    title: "LALAL.AI声音分离工具",
    logo: "/sites/audio/lalal.png",
    link: "https://www.lalal.ai/",
  },
];
</script>

<template>
  <v-menu location="bottom right" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" class="text-none" stacked>
        AI音频
      </v-btn>
    </template>
    <v-list elevation="1" lines="two" density="compact" width="240">
      <v-list-item v-for="(menu, i) in menus" :key="i" :href="menu.link" target="_blank">
        <template v-slot:prepend>
          <v-img :src="menu.logo" :width="30" :alt="menu.title" />
        </template>
        <div class="ml-2">
          <v-list-item-title class="font-weight-bold text-primary">{{
              menu.title
          }}</v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
</style>
