<!--切换主题色-->
<script setup lang="ts">
import { useTheme } from "vuetify";
import { useCustomizeThemeStore } from "@/stores/customizeThemeStore";
import { Icon } from "@iconify/vue";
interface Color {
  colorId: number;
  colorName: string;
  colorValue: string;
}
const customizeTheme = useCustomizeThemeStore();
const theme = useTheme();
const currentColor = ref<Color>({
  colorId: 2,
  colorName: "color2",
  colorValue: "#467794",
});
const primaryColors = ref([
  {
    colorId: 1,
    colorName: "color1",
    colorValue: "#8981F7",
  },
  {
    colorId: 2,
    colorName: "color2",
    colorValue: "#467794",
  },
  {
    colorId: 3,
    colorName: "color3",
    colorValue: "#86C5E3",
  },
  {
    colorId: 4,
    colorName: "color4",
    colorValue: "#92C48D",
  },
  {
    colorId: 5,
    colorName: "color5",
    colorValue: "#BDDB69",
  },
  {
    colorId: 6,
    colorName: "color6",
    colorValue: "#C386F0",
  },
  {
    colorId: 7,
    colorName: "color7",
    colorValue: "#E67C92",
  },
  {
    colorId: 8,
    colorName: "color8",
    colorValue: "#E87361",
  },
  {
    colorId: 9,
    colorName: "color9",
    colorValue: "#ED955F",
  },
  {
    colorId: 10,
    colorName: "color10",
    colorValue: "#F9BC64",
  },
  {
    colorId: 11,
    colorName: "color11",
    colorValue: "#F5DC62",
  },
]);

onMounted(() => updatePrimaryColor(customizeTheme.primaryColor));

watch(currentColor, (newVal) => {
  updatePrimaryColor(newVal);
});

const updatePrimaryColor = (newColor: Color) => {
  theme.themes.value.light.colors.primary = newColor.colorValue;
  theme.themes.value.dark.colors.primary = newColor.colorValue;
  customizeTheme.setPrimaryColor(newColor);
  currentColor.value = newColor;
};
</script>
<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-icon color="primary">mdi-palette-outline</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-item-group class="p-3" v-model="currentColor" selected-class="elevation-12" mandatory>
        <v-item v-for="color in primaryColors" :key="color.colorId" :value="color" v-slot="{ isSelected, toggle }">
          <v-btn @click="toggle" class="text-white mr-1" icon size="30" :color="color.colorValue">
            <Icon width="22" v-if="isSelected" icon="line-md:confirm" />
          </v-btn>
        </v-item>
      </v-item-group>
    </v-list>
  </v-menu>
</template>
