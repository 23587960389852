import menuApp from "./menus/app.menu"
import menuGuide from "./menus/guide.menu"

export default {
  menu: [
    {
      text: "应用设计",
      items: menuApp
    },
    {
      text: "开发指南",
      items: menuGuide
    }
  ]
}
