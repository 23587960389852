<script setup lang="ts">
import router from "@/router"
import { AuthStore } from "@/stores/AuthStore"
import { UserStatusStore } from "@/stores/UserStatusStore"
import StatusMenu from "./StatusMenu.vue"

const authStore = AuthStore()
const userStatusStore = UserStatusStore()

const handleLogout = async () => {
  await router.push("/auth/logout")
}

const navs = [
  {
    title: "资料设置",
    key: "menu.profileDetails",
    link: "/auth/profile",
    icon: "mdi-account-box-outline",
  },
  {
    title: "我的订单",
    key: "menu.orders",
    link: "/order/list",
    icon: "mdi-credit-card-outline",
  },
  {
    title: "我的应用",
    key: "menu.app",
    link: "/app/list",
    icon: "mdi-apps",
  },
  {
    title: "服务条款",
    key: "menu.agree",
    link: "/auth/agree",
    icon: "mdi-file-account-outline",
  },
  {
    title: "隐私政策",
    key: "menu.policy",
    link: "/auth/policy",
    icon: "mdi-eye-lock-outline",
  }
];
</script>

<template>
  <v-btn v-if="!authStore.isLoggedIn" class="mx-2" icon to="/auth/login">
    <v-avatar size="40" class="custom-avatar">
      登录
    </v-avatar>
  </v-btn>
  <v-btn v-if="!authStore.isLoggedIn" class="mx-2" icon to="/auth/signup">
    <v-avatar size="40" class="custom-avatar">
      注册
    </v-avatar>
  </v-btn>

  <v-menu v-if="authStore.isLoggedIn"
    :close-on-content-click="false"
    location="bottom right"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ props }">
      <v-btn class="mx-2" icon v-bind="props">
        <v-badge content="2" :color="userStatusStore.userStatus.color" dot bordered>
          <v-avatar size="40" class="custom-avatar">
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>
    <v-card width="240">
      <v-list lines="three" density="compact">
        <v-list-item to="/auth/profile">
          <template v-slot:prepend>
            <v-avatar size="40" class="custom-avatar">
              <v-img
                :src="authStore.getUserInfo().avatar"
              ></v-img>
            </v-avatar>
          </template>

          <v-list-item-title class="font-weight-bold text-primary">
            {{authStore.getUserInfo().nickname}}
            <StatusMenu />
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider />

      <v-list variant="flat" elevation="0" :lines="false" density="compact">
        <v-list-item
          color="primary"
          v-for="(nav, i) in navs"
          :key="i"
          :to="nav.link"
          link
          density="compact"
          class="pa-2"
        >
          <template v-slot:prepend>
            <v-avatar size="30" class="custom-avatar">
              <v-icon>{{ nav.icon }}</v-icon>
            </v-avatar>
          </template>

          <div>
            <v-list-item-subtitle class="text-body-2">{{
              nav.title
            }}</v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list variant="flat" elevation="0" :lines="false" density="compact">
        <v-list-item color="primary" class="pa-2" to="/help" density="compact">
          <template v-slot:prepend>
            <v-avatar size="30" class="custom-avatar">
              <v-icon>mdi-lifebuoy</v-icon>
            </v-avatar>
          </template>

          <div>
            <v-list-item-subtitle class="text-body-2">
              帮助与反馈
            </v-list-item-subtitle>
          </div>
        </v-list-item>
        <v-list-item
          color="primary"
          link
          @click="handleLogout"
          density="compact"
          class="pa-2"
        >
          <template v-slot:prepend>
            <v-avatar size="30" class="custom-avatar">
              <v-icon>mdi-logout</v-icon>
            </v-avatar>
          </template>

          <div>
            <v-list-item-subtitle class="text-body-2">
              退出
            </v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<style scoped lang="scss">
.custom-avatar {
  border: none; /* 移除边框 */
}
</style>
