export default [
  {
    path: "/coder/quarkus",
    component: () => import("@/views/coder/Quarkus.vue"),
    meta: {
      layout: "coder",
      category: "代码生成",
      title: "Quarkus / SpringBoot",
    },
  },
  {
    path: "/coder/fastapi",
    component: () => import("@/views/coder/FastApi.vue"),
    meta: {
      layout: "coder",
      category: "代码生成",
      title: "FastApi",
    },
  },
  {
    path: "/coder/vuetify",
    component: () => import("@/views/coder/Vuetify.vue"),
    meta: {
      layout: "coder",
      category: "代码生成",
      title: "Vuetify",
    },
  },
  {
    path: "/coder/harmony",
    component: () => import("@/views/coder/Harmony.vue"),
    meta: {
      layout: "coder",
      category: "代码生成",
      title: "Harmony",
    },
  },
];
