export default [
  {
    icon: "mdi-clock-time-three-outline",
    key: "menu.guideQuickStart",
    text: "快速上手",
    link: "/guide/quick-start"
  },
  {
    icon: "mdi-school",
    key: "menu.guideDocs",
    text: "进阶技巧",
    link: "/guide/docs"
  }
]
