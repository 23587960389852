export default [
  {
    path: "/navigation/admin",
    name: "navigation-list-admin",
    component: () => import("@/views/navigation/ListAdmin.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "菜单设置",
      title: "后台导航列表",
    }
  },
  {
    path: "/navigation/user",
    name: "navigation-list-user",
    component: () => import("@/views/navigation/ListUser.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "菜单设置",
      title: "会员导航列表",
    }
  },
  {
    path: "/navigation/create/:id",
    name: "navigation-create",
    component: () => import("@/views/navigation/Create.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "菜单设置",
      title: "创建导航",
    }
  },
  {
    path: "/navigation/modify/:id",
    name: "navigation-modify",
    component: () => import("@/views/navigation/Modify.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "菜单设置",
      title: "编辑导航",
    }
  }
]
