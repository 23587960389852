<script setup lang="ts">
import MainSidebar from "@/components/MainSidebar.vue";
import MainAppbar from "@/components/MainAppbar.vue";
import { useCustomizeThemeStore } from "@/stores/customizeThemeStore";
const customizeTheme = useCustomizeThemeStore();
</script>

<template>
  <MainSidebar />
  <MainAppbar />
  <v-main
    class="main-container"
    v-touch="{
      left: () => (customizeTheme.mainSidebar = false),
      right: () => (customizeTheme.mainSidebar = true),
    }"
  >

    <div class="flex-fill pa-5">
      <slot></slot>
    </div>
  </v-main>
</template>

<style scoped>
.scrollnav {
  height: calc(100vh - 326px);
}
.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
