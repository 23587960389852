export default [
  {
    path: "/domain/list",
    name: "domain-list",
    component: () => import("@/views/domain/List.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "领域类设计",
      title: "领域类列表",
    }
  },
  {
    path: "/domain/create",
    name: "domain-create",
    component: () => import("@/views/domain/Create.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "领域类设计",
      title: "创建领域类",
    }
  },
  {
    path: "/domain/modify/:id",
    name: "domain-modify",
    component: () => import("@/views/domain/Modify.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "领域类设计",
      title: "编辑领域类",
    }
  }
]
