<script setup lang="ts">
import { useDisplay } from "vuetify";
import ToolbarPromptsMenu from "./ToolbarPromptsMenu.vue";
import ToolbarTextsMenu from "./ToolbarTextsMenu.vue";
import ToolbarImagesMenu from "./ToolbarImagesMenu.vue";
import ToolbarVideosMenu from "./ToolbarVideosMenu.vue";
import ToolbarAudiosMenu from "./ToolbarAudiosMenu.vue";
import ToolbarWorktoolsMenu from "./ToolbarWorktoolsMenu.vue";
import ToolbarModelsMenu from "./ToolbarModelsMenu.vue";
import PrimaryColors from "@/components/PrimaryColors.vue";
import ToolbarNotifications from "./ToolbarNotifications.vue";
import ToolbarUser from "./ToolbarUser.vue";
import { useTodoStore } from "@/stores/todoStore";
import ThemeToggle from "./ThemeToggle.vue";
const { mdAndUp } = useDisplay();
const todoStore = useTodoStore();

import { useCustomizeThemeStore } from "@/stores/customizeThemeStore";
const customizeTheme = useCustomizeThemeStore();
</script>

<template>
  <v-app-bar :density="mdAndUp ? 'default' : 'compact'">
    <v-app-bar-nav-icon
      @click="customizeTheme.mainSidebar = !customizeTheme.mainSidebar"
    ></v-app-bar-nav-icon>
    <div class="px-2 d-flex align-center justify-space-between w-100">
      <v-btn class="text-none" to="/home" stacked> AIGC导航 </v-btn>
<!--      <v-btn class="text-none" to="/news" stacked> AI前沿 </v-btn>-->
<!--      <v-btn class="text-none" to="/compare" stacked> AI评测 </v-btn>-->
<!--      <ToolbarPromptsMenu />-->
      <ToolbarTextsMenu />
      <ToolbarImagesMenu />
      <ToolbarVideosMenu />
      <ToolbarAudiosMenu />
      <ToolbarWorktoolsMenu />
      <ToolbarModelsMenu />

      <v-btn class="text-none position-relative" to="/coder" stacked>
        <span class="pa-2">
          AIGC编程
        </span>
        <v-icon
          size="18"
          color="green"
          class="position-absolute"
          style="top: 12px; right: 2px;"
        >
          mdi-leaf
        </v-icon>
      </v-btn>

<!--      <v-btn class="text-none" to="/comfyui" stacked> ComfyUI工作流 </v-btn>-->
<!--      <v-btn class="text-none" to="/market" stacked> AI超市 </v-btn>-->
<!--      <v-btn class="text-none" to="/study" stacked> 涨技能 </v-btn>-->

      <v-spacer></v-spacer>

      <div class="d-flex">
<!--        <ToolbarNotifications />-->
<!--        <v-btn v-if="mdAndUp" icon to="/apps/todo">-->
<!--          <v-badge :content="`${todoStore.getTodoList.length} +`" color="error">-->
<!--            <v-icon>mdi-calendar-check</v-icon>-->
<!--          </v-badge>-->
<!--        </v-btn>-->
<!--        <v-divider vertical thickness="2" inset class="ml-5 mr-1"></v-divider>-->
        <!--切换深色模式/浅色模式-->
        <ThemeToggle />
        <!--选择主题色-->
        <PrimaryColors />
        <ToolbarUser />
      </div>
    </div>
  </v-app-bar>
</template>

<style scoped lang="scss"></style>
