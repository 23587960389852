export default [
  {
    icon: "mdi-apps",
    key: "menu.app",
    text: "应用",
    link: "/app/list",
  },
  {
    icon: "mdi-file-code-outline",
    key: "menu.domain",
    text: "领域类",
    link: "/domain/list",
  },
  {
    icon: "mdi-code-tags-check",
    key: "menu.dto",
    text: "DTO",
    link: "/dto/list",
  },
  {
    icon: "mdi-code-json",
    key: "menu.vo",
    text: "VO",
    link: "/vo/list",
  },
  {
    icon: "mdi-server",
    key: "menu.resource",
    text: "接口设计",
    link: "/resource/list",
  },
  {
    icon: "mdi-alpha-p-box-outline",
    key: "menu.grpc.proto",
    text: "proto设计",
    link: "/grpc/proto",
  },
  {
    icon: "mdi-vector-polyline",
    key: "menu.gRPC",
    text: "gRPC通讯",
    link: "/grpc/list",
  }
]
