// 用户忙闲状态(在线/离开/忙碌/下线)
import { defineStore } from "pinia"

export interface UserStatus {
  code: string
  label: string
  color: string
}

export const UserStatusStore = defineStore({
  id: "userStatus",
  state: () => ({
    userStatus: {
      code: "online",
      label: "在线",
      color: "success"
    }
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ["userStatus"]
      }
    ]
  },
  actions: {
    // 初始化用户忙闲状态
    initializeStatus() {
      const storedStatus = this.userStatus;
      if (storedStatus) {
        this.setStatus(storedStatus)
      }
    },
    // 切换用户忙闲状态
    setStatus(status: UserStatus) {
      this.userStatus = status
    }
  }
})
