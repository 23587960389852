export default [
  {
    path: "/models/sd",
    component: () => import("@/views/models/Sd.vue"),
    meta: {
      requiresAuth: true,
      layout: "public",
      category: "大模型",
      title: "精选SD大模型",
    },
  },
  {
    path: "/models/lora",
    component: () => import("@/views/models/Lora.vue"),
    meta: {
      requiresAuth: true,
      layout: "public",
      category: "大模型",
      title: "精选Lora大模型",
    },
  },
  {
    path: "/models/llm",
    component: () => import("@/views/models/LLM.vue"),
    meta: {
      requiresAuth: true,
      layout: "public",
      category: "大模型",
      title: "精选大语言模型",
    },
  },
  {
    path: "/models/train",
    component: () => import("@/views/models/Train.vue"),
    meta: {
      requiresAuth: true,
      layout: "public",
      category: "大模型",
      title: "大模型训练委托",
    },
  },
];
