import { defineStore } from "pinia";
import { useTheme } from "vuetify";

interface Color {
  colorId: number;
  colorName: string;
  colorValue: string;
}

interface State {
  mainSidebar: boolean;
  theme: string;
  primaryColor: Color;
}

export const useCustomizeThemeStore = defineStore({
  id: "customizeTheme",
  state: (): State => ({
    mainSidebar: true,
    theme: 'light',
    primaryColor: {
      colorId: 2,
      colorName: "color2",
      colorValue: "#467794",
    }
  }),

  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ["mainSidebar", "theme", "primaryColor"],
      },
    ],
  },

  getters: {},
  actions: {
    setTheme(payload: string) {
      this.theme = payload;
    },
    setPrimaryColor(payload: Color) {
      this.primaryColor = payload;
    },
    initializeTheme() {
      // 这里在页面加载时初始化主题
      if (this.theme === 'dark') {
        useTheme().global.name.value = 'dark';
      } else {
        useTheme().global.name.value = 'light';
      }
    }
  }
});
