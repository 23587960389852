export default [
  {
    path: "/dto/list",
    name: "dto-list",
    component: () => import("@/views/dto/List.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "DTO类设计",
      title: "DTO列表",
    }
  },
  {
    path: "/dto/create",
    name: "dto-create",
    component: () => import("@/views/dto/Create.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "DTO类设计",
      title: "创建DTO类",
    }
  },
  {
    path: "/dto/create-from-domain/:id",
    name: "dto-create-from-domain",
    component: () => import("@/views/dto/CreateFromDomain.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "DTO类设计",
      title: "创建DTO类",
    }
  },
  {
    path: "/dto/modify/:id",
    name: "dto-modify",
    component: () => import("@/views/dto/Modify.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "DTO类设计",
      title: "编辑DTO类",
    }
  },
  {
    path: "/dto/config/:id",
    name: "dto-config",
    component: () => import("@/views/dto/Config.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "DTO类设计",
      title: "字段校验配置",
    }
  }
]
