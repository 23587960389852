export default [
  {
    path: "/resource/list",
    name: "resource-list",
    component: () => import("@/views/resource/List.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "接口设计",
      title: "资源类列表",
    }
  },
  {
    path: "/resource/create",
    name: "resource-create",
    component: () => import("@/views/resource/Create.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "接口设计",
      title: "创建资源类",
    }
  },
  {
    path: "/resource/modify/:id",
    name: "resource-modify",
    component: () => import("@/views/resource/Modify.vue"),
    meta: {
      requiresAuth: true,
      layout: "coder",
      category: "接口设计",
      title: "编辑资源类",
    }
  }
]
