<script setup lang="ts">
const menus = [
  {
    title: "可灵AI",
    logo: "/sites/video/klingai.png",
    link: "https://klingai.kuaishou.com/",
  },
  {
    title: "即梦",
    logo: "/sites/video/jimeng.png",
    link: "https://jimeng.jianying.com/",
  },
  {
    title: "Sora",
    logo: "/sites/video/sora.png",
    link: "https://openai.com/index/sora/",
  },
  {
    title: "Runway",
    logo: "/sites/video/runway.png",
    link: "https://app.runwayml.com/",
  },
  {
    title: "Pika",
    logo: "/sites/video/pika.png",
    link: "https://pika.art/",
  },
  {
    title: "Stable Video",
    logo: "/sites/video/stablevideo.png",
    link: "https://www.stablevideo.com/",
  },
  {
    title: "PixVerse",
    logo: "/sites/video/pixverse.png",
    link: "https://app.pixverse.ai/",
  },
  {
    title: "Fliki",
    logo: "/sites/video/fliki.png",
    link: "https://fliki.ai/",
  },
  {
    title: "Lumen5",
    logo: "/sites/video/lumen5.png",
    link: "https://lumen5.com/",
  },
  {
    title: "D-ID 实时人像和虚拟口播",
    logo: "/sites/video/did.png",
    link: "https://www.d-id.com/",
  },
  {
    title: "DomoAI 真人视频转动漫",
    logo: "/sites/video/domoai.png",
    link: "https://domoai.app/",
  },
  {
    title: "AI Sorabot",
    logo: "/sites/video/sorabot.png",
    link: "https://soar.hznhkj.cn/",
  },
  {
    title: "腾讯智影",
    logo: "/sites/video/zenvideo.png",
    link: "https://zenvideo.qq.com/",
  },
  {
    title: "星火绘镜",
    logo: "/sites/video/typemovie.png",
    link: "https://typemovie.art/",
  },
  {
    title: "怪兽AI数字人",
    logo: "/sites/video/guaishouai.png",
    link: "https://www.guaishouai.com/",
  },
];
</script>

<template>
  <v-menu location="bottom right" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" class="text-none" stacked>
        AI视频
      </v-btn>
    </template>
    <v-list elevation="1" lines="two" density="compact" width="260">
      <v-list-item v-for="(menu, i) in menus" :key="i" :href="menu.link" target="_blank">
        <template v-slot:prepend>
          <v-img :src="menu.logo" :width="30" :alt="menu.title" />
        </template>
        <div class="ml-2">
          <v-list-item-title class="font-weight-bold text-primary">{{
              menu.title
          }}</v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
</style>
