<script setup lang="ts">
const menus = [
  {
    title: "爱设计PPT",
    logo: "/sites/office/isheji.png",
    link: "https://ppt.isheji.com/",
  },
  {
    title: "美图设计室AI PPT",
    logo: "/sites/office/designkit.png",
    link: "https://www.designkit.com/ppt/",
  },
  {
    title: "Gamma AI幻灯片",
    logo: "/sites/office/gamma.png",
    link: "https://gamma.app/",
  },
  {
    title: "Mapify(原名Chatmind)",
    logo: "/sites/office/mapify.png",
    link: "https://mapify.so/cn",
  },
  {
    title: "Taskade",
    logo: "/sites/office/taskade.png",
    link: "https://www.taskade.com/",
  },
  {
    title: "百度脑图",
    logo: "/sites/office/naotu_baidu.png",
    link: "https://naotu.baidu.com/",
  },
  {
    title: "ChatPDF",
    logo: "/sites/office/chatpdf.png",
    link: "https://www.chatpdf.com/",
  },
  {
    title: "WPS AI",
    logo: "/sites/text/wps.png",
    link: "https://ai.wps.cn/",
  },
  {
    title: "讯飞智文",
    logo: "/sites/office/zhiwen.png",
    link: "https://zhiwen.xfyun.cn/",
  },
  {
    title: "酷表ChatExcel",
    logo: "/sites/office/chatexcel.png",
    link: "https://chatexcel.com/",
  },
  {
    title: "ChartCube 图表魔方",
    logo: "/sites/office/chartcube.png",
    link: "https://chartcube.alipay.com/",
  },
  {
    title: "博思白板",
    logo: "/sites/office/boardmix.png",
    link: "https://boardmix.cn/ai-whiteboard/",
  },
  {
    title: "讯飞智能翻译平台",
    logo: "/sites/office/xfyunfanyi.png",
    link: "https://fanyi.xfyun.cn/console/trans/doc",
  },
];
</script>

<template>
  <v-menu location="bottom right" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" class="text-none" stacked>
        AI办公
      </v-btn>
    </template>
    <v-list elevation="1" lines="two" density="compact" width="260">
      <v-list-item v-for="(menu, i) in menus" :key="i" :href="menu.link" target="_blank">
        <template v-slot:prepend>
          <v-img :src="menu.logo" :width="30" :alt="menu.title" />
        </template>
        <div class="ml-2">
          <v-list-item-title class="font-weight-bold text-primary">{{
              menu.title
          }}</v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
</style>
